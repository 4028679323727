import React from 'react'

export const onServiceWorkerUpdateReady = () => window.location.reload()

export const wrapRootElement = ({ element }) => (
  <>
    {element}
    <div id='modal-root' />
  </>
)
